/* This file intentionally left blank.
 * Put your custom CSS into your (not the theme's) assets/sass/custom.scss
 */
 .main
 {
 position:relative;	
 width:90%;
 left:5%;
 margin:0px;
 height:100%;
 font-size:20px;
 text-align:center;
 }

 .b
{
    border: 1px solid #99C49E;
	border-radius: 5%;
    max-width: 80%;
    max-height: 80%;
}

#main-image
{
	border: 1px solid black;
	border-radius: 2%;
    min-width: 1%;
    min-height: 1%;
    
    width: 80%;
    height: 80%;

}

.guide p
{
    width:75%;
    left:12.5%;
    position: relative;
    text-align: left;
}

.hdr
{
text-decoration: underline;    
}

.hdr:first-child{text-decoration: none;}

img.sub{

 max-width: 150px;
 max-height: 150px;
}
.images
{

    flex-direction: row;
    justify-content: center;
    align-items: center;    
}

.mid{
    width:50%;    
    left:25%;
    position: relative;
    text-align: left;
}

.cent
{
    width:75%;
    left:12.5%;
    position: relative;
    text-align: left;

}

.toppic
{
width: 20%;
height: 20%;
min-width: 20%;
min-height: 20%;
-webkit-animation: CocoLight 4s infinite;
animation: CocoLight 4s infinite;
}

@keyframes CocoLight
{
50% {opacity: 0.4;}
}
@-webkit-keyframes CocoLight
{
50% {opacity: 0.4;}
}